import * as React from "react";
import './Menu.css'
import {modelSet, RenderEngine} from "../../service/renderEngine";
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';

interface Props{
    materialGroup:any[],
    backgrounds:any[],
    visibilitySet: any[],
    renderSetting:any[],
    engine:RenderEngine,
    toggleMenu:()=>void
}

interface State{
}


export class Menu extends React.Component<Props,State> {
    constructor(props:Props){
        super(props);
    }

    getDropwdown(){

        return this.props.materialGroup.map((e)=>{
            return(
                <Dropdown engine={this.props.engine} material={e}></Dropdown>
            )
        })
    }

    getDropwdownVisibility(){
        return this.props.visibilitySet.map((e)=>{
            return(
                <DropdownVisibility engine={this.props.engine} material={e}></DropdownVisibility>
            )
        })
    }

    getBackground(){
        return this.props.backgrounds.map((e)=>{
            return(
                <option value={e.id}>{e.name}</option>
            )
        })
    }

    getRenderSetting(){
        return this.props.renderSetting.map((e)=>{
            return(
                <option value={e.id}>{e.name}</option>
            )
        })
    }

    onChangeBackground(event){
        console.log("value", event.target.value)
        //this.props.engine.configurator.setEnvironment(event.target.value)
    }

    onChangeRender(event){
        console.log("value", event.target.value)
        this.props.engine.changeRenderSetting(event.target.value)
    }

    render(){
        return(
            <div className="menu">
                <div className="menu-header">
                    <img src={require('./poweredbyvc.png')}/>
                </div>
                <PerfectScrollbar option={{suppressScrollX:true, useBothWheelAxes:true}}>
                    <div className="menu-title">
                        <p>Model</p>
                    </div>
                    <div className="menu-drop upper">
                        {this.getDropwdownVisibility()}
                    </div>
                    <div className="menu-title">
                        <p>Material</p>
                    </div>
                    <div className="menu-drop upper">
                        {this.getDropwdown()}
                    </div>
                    <div className="menu-title">
                        <p>Background</p>
                    </div>
                    <div className="menu-drop">
                        <div className="dropdown">
                            <div className="label"><p>Background</p></div>
                            <select name="Background" onChange={this.onChangeBackground.bind(this)}>
                                {this.getBackground()}
                            </select>
                        </div>
                    </div>
                    <div className="menu-drop">
                        <div className="dropdown">
                            <div className="label"><p>Render Settings</p></div>
                            <select name="Render" onChange={this.onChangeRender.bind(this)}>
                                {this.getRenderSetting()}
                            </select>
                        </div>
                    </div>
                </PerfectScrollbar>
                <div className="arrow" onClick={this.props.toggleMenu}>
                    <i className="fa fa-arrow-circle-left"></i>
                </div>
            </div>
        )
    }
}

interface IDropProps{
    material:any,
    engine:RenderEngine
}

interface IDropState {
}

class Dropdown extends React.Component<IDropProps,IDropState> {
    constructor(props:IDropProps){
        super(props);
        //this.props.engine.configurator.setMaterialGroup(this.props.material.id, this.props.material.mats[0].id)
    }

    getOption(){
        return this.props.material.mats.map((e)=>{
            return(
                <option value={e.id}>{e.name}</option>
            )
        })
    }

    onChange(event){
        console.log("value", event.target.value)
        this.props.engine.configurator.setMaterialGroup(this.props.material.id, event.target.value)
    }

    componentDidMount() {

    }

    render(){
        return(
            <div className="dropdown">
                <div className="label"><p>{this.props.material.name}</p></div>
                <select name={this.props.material.name} onChange={this.onChange.bind(this)}>
                    {this.getOption()}
                </select>
            </div>
        )
    }
}

class DropdownVisibility extends React.Component<IDropProps,IDropState> {
    constructor(props:IDropProps){
        super(props);
        //this.props.engine.configurator.setVisibilitySet(this.props.material.id, this.props.material.group[0], true)
    }

    getOption(){
        return this.props.material.group.map((e)=>{
            return(
                <option value={e}>{modelSet.get(e)}</option>
            )
        })
    }

    onChange(event){
        console.log("value", event.target.value)
        this.props.engine.changeFixtureMenu(this.props.material.id, event.target.value)
    }

    componentDidMount() {

    }

    render(){
        return(
            <div className="dropdown">
                <div className="label"><p>{this.props.material.name}</p></div>
                <select name={this.props.material.name} onChange={this.onChange.bind(this)}>
                    {this.getOption()}
                </select>
            </div>
        )
    }
}

