const data = require('./DodgeChallenger.json')

export function logFix() {
    let validIDs = data.assetList.map(e=>e.id);
    data.info.data.groupings.forEach((e)=>{
        if(!validIDs.includes(e.geometry_id)){
            console.log("id: ", e.geometry_id);
            console.log("name: ", e.id);
        }
    })
    data.info.data.default_state.material_groups.forEach((e)=>{
        if(!validIDs.includes(e.material_id)){
            console.log("id: ", e.material_id);
        }

    })
}