import axios from "axios";

export async function getMetadata():Promise<any>{
    return new Promise((res, rej)=>{
        axios.get("https://srv.3dcommerce.studio/public/shop/getMedata?shop=65d5d4531250a4dba4942ea5").then((response)=>{
            console.log("metadata", response.data.data.metadata);
            res(response.data.data.metadata)
        }).
        catch(()=>{
            res(null)
        })
    })
}